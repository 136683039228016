<template>
	<div id="agreement">
		<h1>劳务协议预览 </h1>
		<div class="box">
			<div class="agree">
				<div class="richTextParsing" v-if="richText" v-html="richText"></div>
				<div class="null" v-if="!richText">
					<img src="../../assets/imgs/EmptyState.png" alt="">
					<span>暂无协议预览</span>
				</div>
			</div>
		</div>
		<h1>劳务信息确认</h1>
		<div class="form box">
			<div class="formBox">
				<el-form ref="form" label-width="100%" label-position="top" v-loading="loading">
					<el-row :gutter="20">
						<el-col :span="12" v-for="item in form" :key="item.id">
							<el-form-item :label="item.label">
								<el-input border="buttom" v-model="item.value" :disabled="item.disabled"
									:placeholder="`请完善${item.label}`">
								</el-input>
								<el-upload class="avatar-uploader" :action="`${baseUrl}/sign/live/doctor/uploadIdCard`"
									v-if="item.label=='身份证号'" :show-file-list="false" :on-success="handleAvatarSuccess1"
									:headers="{token:token}" :before-upload="beforeAvatarUpload">
									<el-button icon="el-icon-upload">上传识别</el-button>
								</el-upload>
								<el-upload class="avatar-uploader"
									:action="`${baseUrl}/sign/live/doctor/uploadBankCard`" v-if="item.label=='银行卡号'"
									:show-file-list="false" :on-success="handleAvatarSuccess3" :headers="{token:token}"
									:before-upload="beforeAvatarUpload">
									<el-button icon="el-icon-upload">上传识别</el-button>
								</el-upload>
							</el-form-item>
						</el-col>
					</el-row>
				</el-form>
			</div>
		</div>
		<div class="opts">
			<el-button @click="onSubmit('form')" :loading="loading">确认并签署</el-button>
		</div>
	</div>
</template>
<script>
	import baseUrl from "@/request/config.js"
	import {
		meetingMessage,
		startSign,
		getMeetDetil
	} from '@/request/api.js';
	export default {
		name: 'agreement',
		data() {
			return {
				baseUrl: '',
				loading: false,
				meetIngId: '',
				form: '',
				richText: '',
				token: '',
			}
		},
		created() {
			this.baseUrl = baseUrl;
			this.meetIngId = this.$route.query.mId;
			this.token = localStorage.getItem('regToken') ? JSON.parse(localStorage.getItem('regToken')).token : JSON
				.parse(localStorage.getItem('token')).token;
			this.getMeetingMessage();
		},
		mounted() {},
		methods: {
			// 检测协议签署状态
			checkSignStatus() {
				let data = {
					meetingId: this.meetIngId
				};
				getMeetDetil(data).then(res => {
					if (res.code == 200) {
						if (res.data.isSign == "NO") {
							setTimeout(() => {
								this.checkSignStatus();
							}, 5000);
						} else {
							this.$router.push({
								name: 'meetlist'
							});
						};
						// console.log(JSON.parse(JSON.stringify(res.data)))
					};
				});
			},
			// 获取会议详情
			getMeetingMessage() {
				let data = {
					meetingId: this.meetIngId
				};
				meetingMessage(data).then(res => {
					if (res.code == 200) {
						this.form = JSON.parse(JSON.stringify(res.data.form));
						this.richText = res.data.html;
						this.loading = false;
						this.checkSignStatus();
					};
				});
			},
			beforeAvatarUpload(file) {
				this.loading = true;
				// const isJPG = file.type === 'image/jpeg';
				const isLt20M = file.size / 10204 / 10204 < 20;
				// if (!isJPG) {
				// 	this.$message.error('上传头像图片只能是 JPG 格式!');
				// }
				if (!isLt20M) {
					this.$message.error('上传头像图片大小不能超过 20MB!');
					this.loading = true;
				};
				return isLt20M;
			},
			handleAvatarSuccess1(res, file) {
				if (res.data.cardId) {
					this.getMeetingMessage();
				} else {
					this.$alert('身份证识别失败，请更换清晰的图片', '提示', {
						confirmButtonText: '确定',
						closeOnClickModal: true,
						type: "error",
						center: true
					});
					this.loading = false;
				};
			},
			handleAvatarSuccess3(res, file) {
				if (res.data.bankNumber) {
					this.getMeetingMessage();
				} else {
					this.$alert('银行卡识别失败，请更换清晰的图片', '提示', {
						confirmButtonText: '确定',
						closeOnClickModal: true,
						type: "error",
						center: true
					});
					this.loading = false;
				};
			},
			onSubmit() {
				this.loading = true;
				let data = {
					meetingId: this.meetIngId,
					dataJson: JSON.stringify(this.form)
				};
				startSign(data).then(res => {
					if (res.code == 200) {
						window.open(res.data.actorSignTaskEmbedUrl);
					} else {
						this.$notify({
							title: '提示',
							message: res.msg,
							position: 'bottom-right',
							type: 'warning'
						});
					};
					this.loading = false;
				});
			}
		}
	}
</script>

<style lang="scss" scoped>
	#agreement {
		padding: 30px 20px;
		h1 {
			font-size: 20px;
			color: #112950;
			border-bottom: 2px solid #F5F7FF;
			padding: 0 20px 10px;
			margin-bottom: 20px;
		}
		.box {
			padding: 40px 30px;
			background-color: #F6F7F9;
			border-radius: 10px;
			margin-bottom: 30px;
			overflow: hidden;
		}
		.agree,
		.form {
			width: 100%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			h2 {
				width: 80%;
				height: 40px;
				background-color: #E6EDF5;
				line-height: 40px;
				border-radius: 5px;
				text-align: center;
				margin-bottom: 20px;
			}
			.richTextParsing,
			.formBox {
				flex: 1;
				width: 100%;
				background-color: #fff;
				min-height: 200px;
				border-radius: 10px;
				padding: 40px;
			}
			.el-form-item {}
			::v-deep .el-form-item__label {
				color: #112950;
			}
			::v-deep .el-form-item__content {
				height: 44px;
				overflow: hidden;
				display: flex;
				align-items: center;
			}
			// .el-input {
			// 	height: 48px;
			// }
			// .el-input-group__append {
			// 	height: 40px;
			// }
			.null {
				min-height: 200px;
				border-radius: 10px;
				padding: 40px 30px;
				width: 100%;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				border: 50px solid #F2F7FB;
				border-left-width: 25vw;
				border-right-width: 25vw;
				img {
					width: 200px;
				}
				span {
					font-size: 20px;
					color: #999;
				}
			}
		}
		.opts {
			.el-button {
				display: block;
				color: #fff;
				height: 50px;
				width: 300px;
				margin: 0 auto 30px;
				background-color: #D43030;
			}
		}
	}
</style>